package dev.zwander.common.ui.components

import androidx.compose.animation.Crossfade
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import dev.zwander.common.ui.Model
import dev.zwander.common.ui.data.StorageReferenceItem
import dev.zwander.common.util.getPlatformFirebase

@Composable
fun AppUI(
    onDownload: (ref: StorageReferenceItem) -> Unit,
    onInstall: (ref: StorageReferenceItem) -> Unit,
    signInUi: @Composable (modifier: Modifier) -> Unit,
    goBack: () -> Unit,
    modifier: Modifier = Modifier
) {
    LaunchedEffect(Model.isSignedIn) {
        if (Model.isSignedIn == true && Model.currentPath == null) {
            Model.currentPath = StorageReferenceItem.FolderReference(
                getPlatformFirebase().storage.getReference("Apps")
            )
        }
    }

    LaunchedEffect(Model.lastUpdateTime) {
        Model.cachedMetadata.clear()
    }

    BoxWithConstraints(
        modifier = modifier,
        contentAlignment = Alignment.Center
    ) {
        val constraints = constraints
        val widthDp = with (LocalDensity.current) {
            constraints.maxWidth.toDp()
        }

        Crossfade(
            targetState = Model.isSignedIn,
            modifier = Modifier.fillMaxSize()
        ) {
            Box(
                modifier = Modifier.fillMaxSize(),
                contentAlignment = Alignment.Center
            ) {
                when (it) {
                    true -> {
                        MainUI(
                            windowWidth = widthDp,
                            modifier = Modifier.fillMaxSize(),
                            onDownload = onDownload,
                            onInstall = onInstall,
                            goBack = goBack,
                        )
                    }

                    false -> {
                        signInUi(Modifier.fillMaxWidth())
                    }

                    else -> {
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.Center
                        ) {
                            CircularProgressIndicator()
                        }
                    }
                }
            }
        }
    }
}
