package dev.zwander.common.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.graphics.painter.ColorPainter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.toComposeImageBitmap
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import dev.icerock.moko.resources.ImageResource
import kotlinx.browser.window
import kotlinx.coroutines.await
import org.jetbrains.skia.Data
import org.jetbrains.skia.Image
import org.jetbrains.skia.svg.SVGDOM
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.fetch.Response

@Composable
actual fun painterResourceImpl(
    resource: ImageResource,
): Painter {
    val bytes: ByteArray? by produceByteArray(url = resource.fileUrl)

    val localBytes: ByteArray? = bytes
    val density: Density = LocalDensity.current
    return remember(localBytes) {
        if (localBytes == null) {
            return@remember ColorPainter(color = Color.Transparent)
        }

        if (resource.fileUrl.endsWith(".svg", ignoreCase = true)) {
            SVGPainter(SVGDOM(Data.makeFromBytes(localBytes)), density)
        } else {
            val skiaImage: Image = Image.makeFromEncoded(bytes = localBytes)
            val imageBitmap: ImageBitmap = skiaImage.toComposeImageBitmap()

            BitmapPainter(image = imageBitmap)
        }
    }
}

@Composable
internal fun produceByteArray(url: String): State<ByteArray?> {
    return produceState<ByteArray?>(null, url) {
        val response: Response = window.fetch(url).await()

        if (response.ok.not()) {
            console.error("can't load data from $url")
            return@produceState
        }

        val buffer: ArrayBuffer = response.arrayBuffer().await()

        value = Int8Array(buffer)
            .unsafeCast<ByteArray>()
    }
}