package dev.zwander.common.ui.components

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import dev.zwander.common.ui.components.dialog.InWindowAlertDialog
import dev.zwander.common.util.getPlatformFirebase
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR
import io.ktor.client.utils.clientDispatcher
import io.ktor.util.InternalAPI
import korlibs.io.serialization.json.Json
import korlibs.io.util.Indenter
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext

@OptIn(InternalAPI::class)
@Composable
fun RoleGranter(modifier: Modifier = Modifier) {
    var email by remember {
        mutableStateOf(getPlatformFirebase().auth.currentUser?.email ?: "")
    }
    var role by remember {
        mutableStateOf("")
    }
    var add by remember {
        mutableStateOf(true)
    }
    var loading by remember {
        mutableStateOf(false)
    }

    var rolesText by remember {
        mutableStateOf<String?>(null)
    }
    var changeText by remember {
        mutableStateOf<String?>(null)
    }

    val scope = rememberCoroutineScope()

    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        OutlinedTextField(
            value = email,
            onValueChange = { email = it },
            label = {
                Text(
                    text = stringResource(MR.strings.email)
                )
            },
            modifier = Modifier.fillMaxWidth(),
            shape = MaterialTheme.shapes.extraLarge
        )

        OutlinedTextField(
            value = role,
            onValueChange = { role = it },
            label = {
                Text(
                    text = stringResource(MR.strings.role)
                )
            },
            modifier = Modifier.fillMaxWidth(),
            shape = MaterialTheme.shapes.extraLarge
        )

        val checkboxSource = remember {
            MutableInteractionSource()
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .heightIn(min = 48.dp)
                .padding(start = 8.dp)
                .clickable(
                    interactionSource = checkboxSource,
                    indication = null,
                ) {
                    add = !add
                },
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = stringResource(MR.strings.add)
            )

            Checkbox(
                checked = add,
                onCheckedChange = { add = it },
                interactionSource = checkboxSource
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            AnimatedVisibility(
                visible = loading,
                enter = fadeIn(),
                exit = fadeOut()
            ) {
                CircularProgressIndicator()
            }

            Spacer(Modifier.weight(1f))

            Button(
                onClick = {
                    scope.launch(Dispatchers.Main) {
                        val claims = withContext(Dispatchers.clientDispatcher(5, "GetRoles")) {
                            getPlatformFirebase().auth.currentUser?.getIdToken(true)?.claims ?: mapOf()
                        }

                        rolesText = claims.entries.joinToString("\n") { (k, v) -> "$k : $v" }
                    }
                }
            ) {
                Text(text = stringResource(MR.strings.roles))
            }

            Spacer(Modifier.size(8.dp))

            Button(
                onClick = {
                    scope.launch(Dispatchers.clientDispatcher(5, "ModifyRole")) {
                        if (email.isBlank() || role.isBlank()) {
                            return@launch
                        }

                        loading = true

                        val functions = getPlatformFirebase().functions
                        val text = try {
                            val result = functions.getHttpsCallable("modifyRole")
                                .call(
                                    hashMapOf(
                                        "email" to email,
                                        "role" to role,
                                        "add" to add
                                    )
                                )

                            val indenter = Indenter()
                            Json.stringifyPretty(Json.parse(result.data.toString()), indenter)
                            indenter.toString(true, "  ")
                        } catch (e: Throwable) {
                            e.printStackTrace()
                            e.message
                        }

                        changeText = text

                        loading = false
                    }
                }
            ) {
                Text(
                    text = stringResource(MR.strings.apply)
                )
            }
        }
    }

    InWindowAlertDialog(
        buttons = {
            TextButton(onClick = { rolesText = null }) {
                Text(text = stringResource(MR.strings.ok))
            }
        },
        text = {
            Text(
                text = rolesText ?: ""
            )
        },
        onDismissRequest = { rolesText = null },
        showing = rolesText != null,
        title = {
            Text(text = stringResource(MR.strings.roles))
        }
    )

    InWindowAlertDialog(
        buttons = {
            TextButton(onClick = { changeText = null }) {
                Text(text = stringResource(MR.strings.ok))
            }
        },
        text = {
            Text(
                text = changeText ?: ""
            )
        },
        onDismissRequest = { changeText = null },
        showing = changeText != null,
        title = {
            Text(text = stringResource(MR.strings.role))
        }
    )
}