package dev.zwander.common.ui

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import dev.zwander.common.data.PlatformMetadata
import dev.zwander.common.data.PlatformStorageReference
import dev.zwander.common.ui.data.StorageReferenceItem

object Model {
    var isSignedIn by mutableStateOf<Boolean?>(null)
    var isAdmin by mutableStateOf(false)
    var currentDownloadReference by mutableStateOf<PlatformStorageReference?>(null)
    var isInstalling by mutableStateOf(false)
    var lastUpdateTime by mutableStateOf(0L)
    var currentPath by mutableStateOf<StorageReferenceItem?>(null)
    var showingRoleGranter by mutableStateOf(false)

    val listItems = mutableStateListOf<StorageReferenceItem>()
    val cachedMetadata = mutableStateMapOf<String, PlatformMetadata>()
}