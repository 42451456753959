package dev.zwander.paidapps.common

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "4bed9ae736a717a5066b999e05463fa6"

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    private val fallbackFileUrl: String =
        js("require(\"localization/devzwanderpaidappscommon_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        ))

    public override val stringsLoader: RemoteJsStringLoader =
        RemoteJsStringLoader.Impl(supportedLocales = supportedLocales, fallbackFileUri =
        fallbackFileUrl)

    public actual val app_name: StringResource = StringResource(key = "app_name", loader =
        stringsLoader)

    public actual val not_signed_in: StringResource = StringResource(key = "not_signed_in", loader =
        stringsLoader)

    public actual val sign_in: StringResource = StringResource(key = "sign_in", loader =
        stringsLoader)

    public actual val sign_out: StringResource = StringResource(key = "sign_out", loader =
        stringsLoader)

    public actual val no_authorized_apps: StringResource = StringResource(key =
        "no_authorized_apps", loader = stringsLoader)

    public actual val google: StringResource = StringResource(key = "google", loader =
        stringsLoader)

    public actual val email: StringResource = StringResource(key = "email", loader = stringsLoader)

    public actual val submit: StringResource = StringResource(key = "submit", loader =
        stringsLoader)

    public actual val check_email: StringResource = StringResource(key = "check_email", loader =
        stringsLoader)

    public actual val back: StringResource = StringResource(key = "back", loader = stringsLoader)

    public actual val add: StringResource = StringResource(key = "add", loader = stringsLoader)

    public actual val uid: StringResource = StringResource(key = "uid", loader = stringsLoader)

    public actual val role: StringResource = StringResource(key = "role", loader = stringsLoader)

    public actual val apply: StringResource = StringResource(key = "apply", loader = stringsLoader)

    public actual val refresh: StringResource = StringResource(key = "refresh", loader =
        stringsLoader)

    public actual val roles: StringResource = StringResource(key = "roles", loader = stringsLoader)

    public actual val install: StringResource = StringResource(key = "install", loader =
        stringsLoader)

    public actual val download: StringResource = StringResource(key = "download", loader =
        stringsLoader)

    public actual val ok: StringResource = StringResource(key = "ok", loader = stringsLoader)

    public override fun values(): List<StringResource> = listOf(app_name, not_signed_in, sign_in,
        sign_out, no_authorized_apps, google, email, submit, check_email, back, add, uid, role,
        apply, refresh, roles, install, download, ok)
  }

  public actual object images : ResourceContainer<ImageResource> {
    public actual val arrow_left: ImageResource = ImageResource(fileUrl =
        js("require(\"images/arrow_left.svg\")") as String, fileName = "arrow_left.svg")

    public actual val download: ImageResource = ImageResource(fileUrl =
        js("require(\"images/download.svg\")") as String, fileName = "download.svg")

    public actual val install: ImageResource = ImageResource(fileUrl =
        js("require(\"images/install.svg\")") as String, fileName = "install.svg")

    public actual val refresh: ImageResource = ImageResource(fileUrl =
        js("require(\"images/refresh.svg\")") as String, fileName = "refresh.svg")

    public actual val chevron_up: ImageResource = ImageResource(fileUrl =
        js("require(\"images/chevron_up.svg\")") as String, fileName = "chevron_up.svg")

    public override fun values(): List<ImageResource> = listOf(arrow_left, download, install,
        refresh, chevron_up)
  }
}
