@file:Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE", "EXPOSED_PARAMETER_TYPE")

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Surface
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.CanvasBasedWindow
import components.SignInUI
import dev.zwander.common.data.PlatformStorageReference
import dev.zwander.common.ui.Model
import dev.zwander.common.ui.components.AppUI
import dev.zwander.common.ui.data.StorageReferenceItem
import dev.zwander.common.ui.theme.PaidAppsTheme
import dev.zwander.common.util.firebasev9.get
import dev.zwander.common.util.firebasev9.getAuth
import dev.zwander.common.util.firebasev9.getDownloadURL
import dev.zwander.common.util.firebasev9.initializeApp
import dev.zwander.common.util.firebasev9.isSignInWithEmailLink
import dev.zwander.common.util.firebasev9.ref
import dev.zwander.common.util.firebasev9.signInWithEmailLink
import dev.zwander.common.util.getPlatformFirebase
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR
import io.ktor.client.utils.clientDispatcher
import io.ktor.util.InternalAPI
import korlibs.io.toJsObject
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.PopStateEvent

@OptIn(InternalAPI::class, ExperimentalComposeUiApi::class)
fun main() {
    val config = hashMapOf(
        "apiKey" to "AIzaSyCrxoLlxoN82XScLhgjI0i3_8YXz4E2J0A",
        "appId" to "1:920346003800:web:0c024a8f1dad313d913d2d",
        "authDomain" to "paid-apps-6cd33.firebaseapp.com",
        "databaseURL" to "https://paid-apps-6cd33-default-rtdb.firebaseio.com",
        "measurementId" to "G-E7HGPHD171",
        "messagingSenderId" to "920346003800",
        "projectId" to "paid-apps-6cd33",
        "storageBucket" to "paid-apps-6cd33.appspot.com"
    )

    val firebase = initializeApp(config.toJsObject())

    getAuth().onAuthStateChanged {
        Model.isSignedIn = it != null
    }

    fun recurseBackstack(path: StorageReferenceItem, backstack: MutableList<String>) {
        if (path.ref.path != "/") {
            backstack.add(0, path.ref.path)

            path.ref.parent?.let { parent ->
                if (parent.ref.fullPath != "/") {
                    recurseBackstack(StorageReferenceItem.FolderReference(parent), backstack)
                }
            }
        }
    }

    onWasmReady {
        val strippedPath = window.location.hash.replace("#", "")
        console.log(strippedPath)
        if (strippedPath.run { this.isNotBlank() && this != "/" }) {
            val currentPath = StorageReferenceItem.FolderReference(
                PlatformStorageReference(
                    ref(
                        getPlatformFirebase().storage.storage,
                        strippedPath,
                    ),
                )
            )
            val backstack = mutableListOf<String>()

            recurseBackstack(currentPath, backstack)

            console.log("BACKSTACK $backstack")

            backstack.forEachIndexed { index, path ->
                if (index == 0) {
                    window.history.replaceState(
                        path,
                        window.document.title,
                        "#$path",
                    )
                } else {
                    window.history.pushState(
                        path,
                        window.document.title,
                        "#$path",
                    )
                }
            }

            Model.currentPath = currentPath
        }

        CanvasBasedWindow {
            val scope = rememberCoroutineScope()

            window.document.title = stringResource(MR.strings.app_name)

            if (isSignInWithEmailLink(getAuth(), window.location.href)) {
                val email = window.localStorage.getItem("emailForSignIn")

                if (email != null) {
                    scope.launch {
                        signInWithEmailLink(getAuth(), email, window.location.href)
                            .await()

                        window.localStorage.removeItem("emailForSignIn")
                    }
                }
            }

            window.addEventListener("popstate", { event ->
                val path = (event as PopStateEvent).state?.toString().run {
                    if (this == null || this == "/") {
                        "/Apps"
                    } else {
                        this
                    }
                }

                Model.currentPath = StorageReferenceItem.FolderReference(
                    PlatformStorageReference(
                        ref(
                            getPlatformFirebase().storage.storage,
                            path,
                        ),
                    ),
                )
            })

            val currentModelPath = Model.currentPath

            LaunchedEffect(key1 = currentModelPath) {
                val currentState = window.history.state?.toString()?.let {
                    StorageReferenceItem.FolderReference(
                        PlatformStorageReference(
                            ref(getPlatformFirebase().storage.storage, it),
                        ),
                    )
                }

                if (currentModelPath != null &&
                    (currentState == null || currentState.ref.parent?.path != currentModelPath.ref.path)
                ) {
                    if (currentState == null && currentModelPath.ref.path == "/Apps") {
                        return@LaunchedEffect
                    }

                    if (currentModelPath.ref.path == currentState?.ref?.path) {
                        return@LaunchedEffect
                    }

                    if (window.location.hash.replace("#", "") == currentModelPath.ref.path) {
                        return@LaunchedEffect
                    }

                    println("Model-based push ${window.location.hash} ${currentState?.ref?.path} ${currentModelPath.ref.path}")

                    window.history.pushState(
                        currentModelPath.ref.path,
                        window.document.title,
                        "#${currentModelPath.ref.path}",
                    )
                }
            }

            PaidAppsTheme(
                darkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches,
            ) {
                Surface(
                    modifier = Modifier.fillMaxSize()
                ) {
                    LaunchedEffect(Model.isSignedIn) {
                        Model.isAdmin = if (Model.isSignedIn == true) {
                            withContext(Dispatchers.clientDispatcher(5, "AdminCheck")) {
                                getAuth(firebase).currentUser?.getIdTokenResult(false)
                                    ?.await()?.claims?.get("admin") == true
                            }
                        } else {
                            false
                        }
                    }

                    AppUI(
                        modifier = Modifier.fillMaxSize().padding(16.dp),
                        signInUi = {
                            SignInUI(modifier = it)
                        },
                        onDownload = {
                            scope.launch {
                                val url = getDownloadURL(it.ref.ref).await()
                                window.open(url, target = "_blank")
                            }
                        },
                        onInstall = {},
                        goBack = {
                            window.history.back()
                        },
                    )
                }
            }
        }
    }
}
