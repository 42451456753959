package dev.zwander.common.ui.components.filebrowser

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import dev.zwander.common.ui.data.StorageReferenceItem

@Composable
fun FileBrowser(
    onDownload: (reference: StorageReferenceItem) -> Unit,
    onInstall: (reference: StorageReferenceItem) -> Unit,
    goBack: () -> Unit,
    modifier: Modifier = Modifier
) {
    var loading by remember {
        mutableStateOf(true)
    }

    Column(
        modifier = modifier
    ) {
        AddressBar(
            isLoading = loading,
            goBack = goBack,
            modifier = Modifier.fillMaxWidth()
                .heightIn(min = 48.dp)
        )

        Spacer(Modifier.size(16.dp))

        ListUI(
            loading = loading,
            onDownload = onDownload,
            onInstall = onInstall,
            onLoadingStateChanged = { loading = it },
            modifier = Modifier.fillMaxWidth()
        )
    }
}
