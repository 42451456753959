package dev.zwander.common.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.desc.Resource
import dev.icerock.moko.resources.desc.StringDesc
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch

@Composable
actual fun stringResourceImpl(resource: StringResource, callback: (String) -> Unit) {
    LaunchedEffect(resource) {
        async(Dispatchers.Unconfined) {
            val r = StringDesc.Resource(resource).localized()
            launch(Dispatchers.Main) {
                callback(r)
            }
        }
    }
}
