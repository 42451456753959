package dev.zwander.common.data

import dev.zwander.common.util.firebasev9.FullMetadata
import dev.zwander.common.util.firebasev9.ListResult
import dev.zwander.common.util.firebasev9.StorageReference
import dev.zwander.common.util.firebasev9.getMetadata
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlin.js.Date

actual class PlatformListAllResult(val result: ListResult) {
    actual val items: List<PlatformStorageReference>
        get() = result.items.map { PlatformStorageReference(it) }
    actual val prefixes: List<PlatformStorageReference>
        get() = result.prefixes.map { PlatformStorageReference(it) }
}

actual class PlatformStorageReference(val ref: StorageReference) {
    actual val name: String
        get() = ref.name
    actual val path: String
        get() = "/${ref.fullPath}"
    actual val parent: PlatformStorageReference?
        get() = ref.parent?.let { PlatformStorageReference(it) }

    actual suspend fun listAll(): PlatformListAllResult {
        return PlatformListAllResult(dev.zwander.common.util.firebasev9.listAll(ref).await())
    }

    actual suspend fun metadata(): PlatformMetadata {
        return PlatformMetadata(getMetadata(ref).await())
    }
}

actual class PlatformMetadata(val metadata: FullMetadata) {
    actual val creationTimeMillis: Long
        get() = Date(metadata.timeCreated).getTime().toLong()
    actual val creationTimeString: String?
        get() = Date(metadata.timeCreated).toLocaleDateString(
            window.navigator.languages.unsafeCast<Array<String>>()
        )
}
