package dev.zwander.common.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import dev.icerock.moko.resources.StringResource

@Composable
fun stringResource(resource: StringResource): String {
    var resolved by remember(resource) {
        mutableStateOf("")
    }

    stringResourceImpl(resource) {
        resolved = it
    }

    return resolved
}

@Composable
expect fun stringResourceImpl(resource: StringResource, callback: (String) -> Unit)
