package dev.zwander.common.ui.components.filebrowser

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.size
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import dev.zwander.common.ui.Model
import dev.zwander.common.util.painterResource
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR

@Composable
fun AddressBar(
    isLoading: Boolean,
    goBack: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.spacedBy(8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row {
            AnimatedVisibility(visible = Model.currentPath?.ref?.path.run { this != null && this != "/Apps" }) {
                IconButton(
                    onClick = goBack,
                ) {
                    Icon(
                        painter = painterResource(MR.images.arrow_left),
                        contentDescription = stringResource(MR.strings.back),
                        modifier = Modifier.size(24.dp)
                    )
                }
            }

            IconButton(onClick = { Model.lastUpdateTime++ }) {
                Icon(
                    painter = painterResource(MR.images.refresh),
                    contentDescription = stringResource(MR.strings.refresh),
                    modifier = Modifier.size(24.dp)
                )
            }
        }

        Text(
            text = Model.currentPath?.ref?.path ?: "",
            modifier = Modifier.weight(1f)
        )

        AnimatedVisibility(
            visible = isLoading,
            enter = fadeIn(),
            exit = fadeOut()
        ) {
            CircularProgressIndicator(
                modifier = Modifier.size(24.dp)
            )
        }
    }
}
