package dev.zwander.common.ui.components.filebrowser

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.Crossfade
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.Card
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import dev.zwander.common.ui.Model
import dev.zwander.common.ui.data.StorageReferenceItem
import dev.zwander.common.util.getPlatformFirebase
import dev.zwander.common.util.painterResource
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR
import io.ktor.client.utils.clientDispatcher
import io.ktor.util.InternalAPI
import korlibs.memory.Platform
import korlibs.time.PatternDateFormat
import korlibs.time.format
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async

val format = PatternDateFormat("MMM dd, yyyy")

@OptIn(ExperimentalMaterial3Api::class, InternalAPI::class)
@Composable
fun ListUI(
    loading: Boolean,
    onDownload: (reference: StorageReferenceItem) -> Unit,
    onInstall: (reference: StorageReferenceItem) -> Unit,
    onLoadingStateChanged: (Boolean) -> Unit,
    modifier: Modifier = Modifier
) {
    LaunchedEffect(key1 = Model.currentPath, key2 = Model.lastUpdateTime) {
        onLoadingStateChanged(true)

        try {
            async(Dispatchers.clientDispatcher(5, "ListItems")) {
                val customClaims =
                    getPlatformFirebase().auth.currentUser?.getIdToken(true)?.claims ?: mapOf()
                val items = Model.currentPath?.ref?.listAll()?.run {
                    items.map {
                        StorageReferenceItem.FileReference(it).also { f ->
                            if (!Model.cachedMetadata.containsKey(f.ref.path)) {
                                Model.cachedMetadata[f.ref.path] = f.ref.metadata()
                            }
                        }
                    } + prefixes.mapNotNull {
                        if (customClaims.containsKey(it.name) ||
                            customClaims.containsKey("all-possible-apps") ||
                            customClaims.containsKey("patreon-member")
                        ) {
                            StorageReferenceItem.FolderReference(it)
                        } else {
                            null
                        }
                    }
                } ?: listOf()

                Model.listItems.clear()
                Model.listItems.addAll(items)
                onLoadingStateChanged(false)
            }
        } catch (e: Throwable) {
            e.printStackTrace()
            onLoadingStateChanged(false)
        }
    }

    Crossfade(
        targetState = !loading && Model.listItems.isEmpty(),
        modifier = modifier,
    ) { unauthorized ->
        if (unauthorized) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight(),
                contentAlignment = Alignment.Center
            ) {
                Text(text = stringResource(MR.strings.no_authorized_apps))
            }
        } else {
            AnimatedVisibility(
                visible = !loading,
                enter = fadeIn(),
                exit = fadeOut(),
                modifier = Modifier.fillMaxWidth()
            ) {
                LazyColumn(
                    modifier = Modifier.fillMaxWidth(),
                    verticalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    items(Model.listItems, { it.ref.path }) { item ->
                        val isFile = item is StorageReferenceItem.FileReference

                        Card(
                            modifier = Modifier.fillMaxWidth(),
                            onClick = {
                                if (!isFile) {
                                    Model.currentPath = item
                                }
                            }
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .heightIn(min = 48.dp)
                                    .padding(16.dp),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                if (!isFile) {
                                    Spacer(Modifier.weight(1f))
                                }

                                Column(
                                    horizontalAlignment = Alignment.Start
                                ) {
                                    Text(
                                        text = item.ref.name,
                                        fontWeight = if (isFile) FontWeight.Bold else FontWeight.Normal
                                    )

                                    if (isFile) {
                                        Text(
                                            text = Model.cachedMetadata[item.ref.path]?.creationTimeMillis?.let {
                                                format.format(it)
                                            } ?: ""
                                        )
                                    }
                                }

                                Spacer(Modifier.weight(1f))

                                if (item is StorageReferenceItem.FileReference) {
                                    IconButton(onClick = { onDownload(item) }) {
                                        Icon(
                                            painter = painterResource(MR.images.download),
                                            contentDescription = stringResource(MR.strings.download),
                                            modifier = Modifier.size(24.dp)
                                        )
                                    }

                                    if (Platform.runtime.isAndroid) {
                                        IconButton(onClick = { onInstall(item) }) {
                                            Icon(
                                                painter = painterResource(MR.images.install),
                                                contentDescription = stringResource(MR.strings.install),
                                                modifier = Modifier.size(24.dp)
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
