package dev.zwander.common.util

import dev.zwander.common.data.PlatformStorageReference
import dev.zwander.common.util.firebasev9.Auth
import dev.zwander.common.util.firebasev9.FirebaseStorage
import dev.zwander.common.util.firebasev9.Functions
import dev.zwander.common.util.firebasev9.HttpsCallable
import dev.zwander.common.util.firebasev9.IdTokenResult
import dev.zwander.common.util.firebasev9.User
import dev.zwander.common.util.firebasev9.getAuth
import dev.zwander.common.util.firebasev9.getFunctions
import dev.zwander.common.util.firebasev9.getStorage
import dev.zwander.common.util.firebasev9.httpsCallable
import dev.zwander.common.util.firebasev9.ref
import korlibs.io.jsEmptyArray
import korlibs.io.jsEmptyObj
import korlibs.io.jsObjectToMap
import kotlinx.coroutines.await

actual class PlatformFirebase {
    actual val auth: PlatformFirebaseAuth
        get() = PlatformFirebaseAuth(getAuth())
    actual val storage: PlatformFirebaseStorage
        get() = PlatformFirebaseStorage(getStorage())
    actual val functions: PlatformFirebaseFunctions
        get() = PlatformFirebaseFunctions(getFunctions())
}

actual class PlatformFirebaseAuth(val auth: Auth) {
    actual val currentUser: PlatformFirebaseAuthUser?
        get() = auth.currentUser?.let { PlatformFirebaseAuthUser(it) }

    actual suspend fun signOut() {
        auth.signOut().await()
    }
}

actual class PlatformFirebaseAuthUser(val user: User) {
    actual suspend fun getIdToken(refresh: Boolean): PlatformFirebaseIDToken {
        return PlatformFirebaseIDToken(
            dev.zwander.common.util.firebasev9.getIdTokenResult(user, refresh).await()
        )
    }

    actual val uid: String
        get() = user.uid
    actual val email: String?
        get() = user.email
}

actual class PlatformFirebaseIDToken(val token: IdTokenResult) {
    actual val claims: HashMap<String, Any>
        get() = HashMap(jsObjectToMap(token.claims))
}

actual class PlatformFirebaseStorage(val storage: FirebaseStorage) {
    private val root = ref(storage)

    actual fun getReference(ref: String): PlatformStorageReference {
        return PlatformStorageReference(ref(root, ref))
    }
}

private val platformFirebase = PlatformFirebase()

actual fun getPlatformFirebase(): PlatformFirebase {
    return platformFirebase
}

actual class PlatformFirebaseFunctions(val functions: Functions) {
    actual fun getHttpsCallable(endpoint: String): HttpsCallableReference {
        return HttpsCallableReference(
            httpsCallable(functions, endpoint)
        )
    }
}

actual class HttpsCallableReference(val ref: HttpsCallable<Any, Any>) {
    actual suspend fun call(data: Any): HttpsCallableResult {
        val actualData = when (data) {
            is Map<*, *> -> {
                val obj = jsEmptyObj()

                data.forEach { (k, v) ->
                    obj[k.toString()] = v.toString()
                }

                obj
            }
            is Array<*> -> {
                val array = jsEmptyArray()

                data.forEach { array.push(it.toString()) as Unit }

                array
            }
            is IntArray -> {
                val array = jsEmptyArray()

                data.forEach { array.push(it) as Unit }

                array
            }
            is FloatArray -> {
                val array = jsEmptyArray()

                data.forEach { array.push(it) as Unit }

                array
            }
            is LongArray -> {
                val array = jsEmptyArray()

                data.forEach { array.push(it) as Unit }

                array
            }
            is Iterable<*> -> {
                val array = jsEmptyArray()

                data.forEach { array.push(it.toString()) as Unit }

                array
            }
            else -> jsEmptyObj()
        }

        return HttpsCallableResult(ref(actualData).await())
    }
}

actual class HttpsCallableResult(val result: dev.zwander.common.util.firebasev9.HttpsCallableResult<Any>) {
    actual val data: Any?
        get() = result.data
}