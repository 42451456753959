package dev.zwander.common.ui.components

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.expandVertically
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.shrinkVertically
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.OutlinedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import dev.zwander.common.ui.Model
import dev.zwander.common.ui.components.filebrowser.FileBrowser
import dev.zwander.common.ui.data.StorageReferenceItem
import dev.zwander.common.util.getPlatformFirebase
import dev.zwander.common.util.painterResource
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR
import kotlinx.coroutines.launch

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun MainUI(
    windowWidth: Dp,
    onDownload: (ref: StorageReferenceItem) -> Unit,
    onInstall: (ref: StorageReferenceItem) -> Unit,
    goBack: () -> Unit,
    modifier: Modifier = Modifier
) {
    val scope = rememberCoroutineScope()
    val maxWidth = 800.dp

    @Composable
    fun Left(
        modifier: Modifier
    ) {
        Column(
            modifier = modifier,
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FileBrowser(
                onDownload = onDownload,
                onInstall = onInstall,
                goBack = goBack,
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
            )

            Button(
                onClick = {
                    scope.launch {
                        getPlatformFirebase().auth.signOut()
                        Model.isSignedIn = false
                    }
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = stringResource(MR.strings.sign_out)
                    )
                    Text(
                        text = getPlatformFirebase().auth.currentUser?.email ?: ""
                    )
                }
            }
        }
    }

    @Composable
    fun Right(
        modifier: Modifier,
        row: Boolean = false,
    ) {
        Column(
            modifier = modifier,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            if (!row) {
                val rotation by animateFloatAsState(if (Model.showingRoleGranter) 180f else 0f)

                OutlinedCard(
                    onClick = {
                        Model.showingRoleGranter = !Model.showingRoleGranter
                    },
                    modifier = Modifier.fillMaxWidth(),
                    border = BorderStroke(width = 0.dp, Color.Transparent)
                ) {
                    Box(
                        modifier = Modifier.fillMaxWidth()
                            .heightIn(min = 32.dp),
                        contentAlignment = Alignment.Center
                    ) {
                        Icon(
                            painter = painterResource(MR.images.chevron_up),
                            contentDescription = stringResource(MR.strings.roles),
                            modifier = Modifier.size(24.dp).rotate(rotation)
                        )
                    }
                }
            }

            AnimatedVisibility(
                visible = (Model.showingRoleGranter || row),
                enter = expandVertically() + fadeIn(),
                exit = shrinkVertically() + fadeOut()
            ) {
                RoleGranter(
                    modifier = Modifier.fillMaxWidth()
                )
            }
        }
    }

    if (windowWidth <= maxWidth) {
        Column(
            modifier = modifier,
            verticalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            Left(
                Modifier.fillMaxWidth()
                    .weight(1f)
            )

            Right(
                Modifier.wrapContentHeight()
                    .fillMaxWidth()
            )
        }
    } else {
        Row(
            modifier = modifier,
            horizontalArrangement = Arrangement.spacedBy(32.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier.fillMaxHeight()
                    .weight(1f),
                contentAlignment = Alignment.Center
            ) {
                Left(
                    modifier = Modifier.fillMaxHeight()
                        .widthIn(max = maxWidth)
                )
            }

            AnimatedVisibility(
                visible = Model.isAdmin,
                modifier = Modifier.weight(1f),
                enter = expandVertically() + fadeIn(),
                exit = shrinkVertically() + fadeOut()
            ) {
                Box(
                    contentAlignment = Alignment.Center
                ) {
                    Right(
                        modifier = Modifier
                            .widthIn(max = maxWidth),
                        row = true
                    )
                }
            }
        }
    }
}
