package dev.zwander.common.util

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.painter.Painter
import dev.icerock.moko.resources.ImageResource

@Composable
fun painterResource(resource: ImageResource): Painter {
    return painterResourceImpl(resource)
}

@Composable
expect fun painterResourceImpl(resource: ImageResource): Painter
