@file:Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE", "EXPOSED_PARAMETER_TYPE")

package dev.zwander.common.ui.theme

import androidx.compose.material3.Typography
import androidx.compose.material3.tokens.TypographyTokens
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp

fun makeTypography(family: FontFamily): Typography {
    return Typography(
        displayLarge = TypographyTokens.DisplayLarge.copy(
            fontFamily = family,
        ),
        displayMedium = TypographyTokens.DisplayMedium.copy(
            fontFamily = family
        ),
        displaySmall = TypographyTokens.DisplaySmall.copy(
            fontFamily = family
        ),
        headlineLarge = TypographyTokens.HeadlineLarge.copy(
            fontFamily = family
        ),
        headlineMedium = TypographyTokens.HeadlineMedium.copy(
            fontFamily = family
        ),
        headlineSmall = TypographyTokens.HeadlineSmall.copy(
            fontFamily = family
        ),
        titleLarge = TypographyTokens.TitleLarge.copy(
            fontFamily = family
        ),
        titleMedium = TypographyTokens.TitleMedium.copy(
            fontFamily = family
        ),
        titleSmall = TypographyTokens.TitleSmall.copy(
            fontFamily = family
        ),
        bodyLarge = TypographyTokens.BodyLarge.copy(
            fontFamily = family
        ),
        bodyMedium = TypographyTokens.BodyMedium.copy(
            fontFamily = family
        ),
        bodySmall = TypographyTokens.BodySmall.copy(
            fontFamily = family
        ),
        labelLarge = TypographyTokens.LabelLarge.copy(
            fontFamily = family
        ),
        labelMedium = TypographyTokens.LabelMedium.copy(
            fontFamily = family
        ),
        labelSmall = TypographyTokens.LabelSmall.copy(
            fontFamily = family
        )
    )
}

// Set of Material typography styles to start with
val Typography = Typography(
    bodyLarge = TextStyle(
        fontFamily = FontFamily.Default,
        fontWeight = FontWeight.Normal,
        fontSize = 16.sp,
        lineHeight = 24.sp,
        letterSpacing = 0.5.sp
    )
    /* Other default text styles to override
    titleLarge = TextStyle(
        fontFamily = FontFamily.Default,
        fontWeight = FontWeight.Normal,
        fontSize = 22.sp,
        lineHeight = 28.sp,
        letterSpacing = 0.sp
    ),
    labelSmall = TextStyle(
        fontFamily = FontFamily.Default,
        fontWeight = FontWeight.Medium,
        fontSize = 11.sp,
        lineHeight = 16.sp,
        letterSpacing = 0.5.sp
    )
    */
)