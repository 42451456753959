package dev.zwander.common.ui.data

import dev.zwander.common.data.PlatformStorageReference

sealed class StorageReferenceItem(val ref: PlatformStorageReference) {
    class FileReference(ref: PlatformStorageReference) : StorageReferenceItem(ref)
    class FolderReference(ref: PlatformStorageReference) : StorageReferenceItem(ref)

    override fun equals(other: Any?): Boolean {
        return other != null &&
                other::class.simpleName == this::class.simpleName &&
                (other as? StorageReferenceItem)?.ref?.path == this.ref.path
    }

    override fun hashCode(): Int {
        return ref.hashCode()
    }
}