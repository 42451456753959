package components

import androidx.compose.animation.Crossfade
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.google.accompanist.flowlayout.FlowRow
import dev.zwander.common.ui.Model
import dev.zwander.common.ui.components.dialog.InWindowAlertDialog
import dev.zwander.common.util.firebasev9.ActionCodeSettings
import dev.zwander.common.util.firebasev9.GoogleAuthProvider
import dev.zwander.common.util.firebasev9.getAuth
import dev.zwander.common.util.firebasev9.sendSignInLinkToEmail
import dev.zwander.common.util.firebasev9.signInWithPopup
import dev.zwander.common.util.stringResource
import dev.zwander.paidapps.common.MR
import kotlinx.browser.window
import kotlinx.coroutines.async
import kotlinx.coroutines.await

@Composable
fun SignInUI(
    modifier: Modifier = Modifier
) {
    val scope = rememberCoroutineScope()

    var showingEmailDialog by remember {
        mutableStateOf(false)
    }
    var sentEmailLink by remember(showingEmailDialog) {
        mutableStateOf<Boolean?>(false)
    }
    var currentEmail by remember(showingEmailDialog) {
        mutableStateOf("")
    }

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = stringResource(MR.strings.not_signed_in)
        )

        Spacer(Modifier.size(16.dp))

        FlowRow(
            mainAxisSpacing = 8.dp,
            crossAxisSpacing = 8.dp
        ) {
            Button(
                onClick = {
                    scope.async {
                        Model.isSignedIn = null
                        val result = signInWithPopup(getAuth(), GoogleAuthProvider()).await()
                        Model.isSignedIn = result.user != null
                    }
                }
            ) {
                Text(text = stringResource(MR.strings.google))
            }

            Button(
                onClick = {
                    showingEmailDialog = true
                }
            ) {
                Text(text = stringResource(MR.strings.email))
            }
        }
    }

    InWindowAlertDialog(
        showing = showingEmailDialog,
        onDismissRequest = {
            if (sentEmailLink != null) {
                showingEmailDialog = false
            }
        },
        buttons = {
            Spacer(Modifier.weight(1f))

            if (sentEmailLink == true) {
                TextButton(
                    onClick = {
                        showingEmailDialog = false
                    }
                ) {
                    Text(text = stringResource(MR.strings.ok))
                }
            } else {
                TextButton(
                    onClick = {
                        scope.async {
                            try {
                                sentEmailLink = null
                                val result = sendSignInLinkToEmail(
                                    getAuth(),
                                    currentEmail,
                                    object : ActionCodeSettings {
                                        override val android = null
                                        override val dynamicLinkDomain = "paidapps.page.link"
                                        override val handleCodeInApp = true
                                        override val iOS = null
                                        override val url = "https://paidapps.zwander.dev/finishSignIn"
                                    }).await()

                                window.localStorage.setItem(
                                    "emailForSignIn",
                                    currentEmail
                                )

                                sentEmailLink = true
                            } catch (e: Throwable) {
                                e.printStackTrace()
                            }
                        }
                    },
                    enabled = currentEmail.isNotBlank() && sentEmailLink != null
                ) {
                    Text(text = stringResource(MR.strings.submit))
                }
            }
        },
        text = {
            Crossfade(
                modifier = Modifier.fillMaxWidth(),
                targetState = sentEmailLink
            ) {
                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    when (it) {
                        true -> {
                            Text(
                                text = stringResource(MR.strings.check_email)
                            )
                        }

                        false -> {
                            OutlinedTextField(
                                value = currentEmail,
                                shape = MaterialTheme.shapes.extraLarge,
                                label = {
                                    Text(text = stringResource(MR.strings.email))
                                },
                                onValueChange = { currentEmail = it }
                            )
                        }

                        else -> {
                            CircularProgressIndicator()
                        }
                    }
                }
            }
        },
        title = {
            Text(text = stringResource(MR.strings.email))
        }
    )
}
